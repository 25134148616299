<template>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="meetingsData"
        :search="search"
        class="elevation-1"
      >
    
      </v-data-table>
    </v-container>
  </template>
  
  <script>
  
  export default {
    data() {
      return {
        headers: [
          { text: 'Full Name', value: 'user_full_name' },
          { text: 'Class', value: 'room_name' },
          { text: 'Access ID', value: 'access_id' },
          { text: 'Duration', value: 'duration' },
        ],
        meetingsData: [],
        search: '',
      };
    },
    mounted() {
      this.fetchMeetingsData();
    },
    methods: {
       fetchMeetingsData() {
       
         this.$api.get('/meetings/meetings_session/')
         .then((response)=>{this.meetingsData = response.data.results})
          
        
      },
   
    },
  };
  </script>
  
  <style>
  /* Your custom styles here */
  </style>
  